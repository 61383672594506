import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import GoogleMapReact from 'google-map-react'
import ReactGA from 'react-ga'
import { isMobile, isIOS } from "react-device-detect"
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from "luxon"
import { confirmAlert } from 'react-confirm-alert';

import Environment from '../../utils/environment'
import styledTheme from '../../styles/theme'

import SiteLayout from '../../containers/layout/SiteLayout'
import HelmetTitle from '../../components/layout/HelmetTitle'

import { DisclaimerContainer, DisclaimerCloseIcon, ModalAndMenuScreenOverlay, UserLocationSettingsButtonContainer, UserLocationSettingsButtonInner } from './styled-elements'

import { fetchPublicSites, fetchMemberSites } from '../../actions/SiteActions'
import { fetchDataViewHash, clearDataViewHash } from '../../actions/DataViewActions'
import { fetchAppSettings } from '../../actions/AppSettingActions'
import { fetchMetricDisplayStatuses } from '../../actions/MetricDisplayStatusActions'
import { updateUserMapCenterAndZoomLevel } from '../../actions/UserActions'

import { fetchGrowingLocations, updateGrowingLocation } from '../../actions/GrowingLocationActions'
import {
  fetchGrowingSeasons,
  updateGrowingSeason,
  deleteGrowingSeason,
  GrowingSeasonDetailModelDefaultData,
  ConvertGrowingSeasonUpdateParams,
  IsGrowingSeasonLocationValid
} from '../../actions/GrowingSeasonActions'

import MapMarker from '../../components/map/MapMarker'
import MapDraggableStar from '../../components/map/MapDraggableStar'
import SiteStatusPopup from '../../components/map/SiteStatusPopup'
import Legend from '../../components/map/Legend'
import OptionsPanel from '../../components/map/OptionsPanel'
import GrowingSeasonSettingsPanel from '../../components/map/GrowingSeasonSettingsPanel'
import Dashboard from '../../components/dataViews/Dashboard'
import Graph from '../../components/dataViews/Graph'
import UpgradeRequiredModal from '../../components/UpgradeRequiredModal'

import MapSettings from '../../components/icons/MapSettings'
import {
  companyHasGrowingSeasons,
  companyHasHomeLocation,
  companyHasMenu,
  companyIsAdmin
} from "../../utils/companyUtils";
import { initializeMapCenterAndZoomLevel } from "../../utils/mapUtils";

const MetricsValuesLabels = [
  { value: 'sigmaw10', label: 'Hazardous Inversion', metric_decimal_places_key: 'sigmaw_map' },
  { value: 'ann_2hr_sigma_w', label: '2hr Predict: Hazardous Inversion', metric_decimal_places_key: 'ann_2hr_sigma_w' },
  { value: 'ghcop', label: 'Grain Harvest Code of Practice (GHCoP, wind@2m)', metric_decimal_places_key: 'ghcop' },
  { value: 'vtd', label: 'Inversion: VTD', metric_decimal_places_key: 'vtd' },
  { value: 'temperature', label: 'Temperature – 1.2m', metric_decimal_places_key: 'temperature' },
  { value: 'relative_humidity', label: 'Relative Humidity – 1.2m', metric_decimal_places_key: 'relative_humidity' },
  { value: 'delta_t', label: 'Delta T', metric_decimal_places_key: 'delta_t' },
  { value: 'wind_speed_2m', label: 'Wind Speed – 2m', metric_decimal_places_key: 'wind_speed' },
  { value: 'wind_speed_10m', label: 'Wind Speed – 10m', metric_decimal_places_key: 'wind_speed' },
  { value: 'dew_point', label: 'Dew Point', metric_decimal_places_key: 'wind_speed' },
  { value: 'current_day_rainfall', label: 'Rainfall since 9am', metric_decimal_places_key: 'rainfall' },
  { value: 'previous_day_rainfall', label: 'Rainfall 24h to 9am', metric_decimal_places_key: 'rainfall' },
  { value: 'rainfall_month', label: 'Rainfall this month', metric_decimal_places_key: 'rainfall' },
  { value: 'rainfall_year', label: 'Rainfall this year', metric_decimal_places_key: 'rainfall' },
  { value: 'gfdi', label: 'GFDI (wind@10m)', metric_decimal_places_key: 'gfdi' },
  { value: 'evapotranspiration', label: 'Evapotranspiration', metric_decimal_places_key: 'evapotranspiration_map' },
  { value: 'thermal_time', label: 'Thermal Time since May 1', metric_decimal_places_key: 'thermal_vernalisation_time_map' },
  { value: 'vernalisation_time', label: 'Vernalisation Time since May 1', metric_decimal_places_key: 'thermal_vernalisation_time_map' },
  { value: 'adjusted_thermal_time', label: 'Adjusted Thermal Time since May 1', metric_decimal_places_key: 'thermal_vernalisation_time_map' },
  { value: 'downy_mildew', label: 'Downy Mildew Risk' },
  { value: 'etiella', label: 'Etiella Susceptibility', metric_decimal_places_key: 'temperature' },
  { value: 'rlem', label: 'RLEM Susceptibility' },
  { value: 'ag360_flystrike', label: 'Flystrike Risk (5 day)' },
  { value: 'ag360_heat_chill_risk', label: 'Heat/Chill Risk (5 day)' },
  { value: 'cci', label: 'Animal Comfort Index', metric_decimal_places_key: 'cci_map' },
]

const MetricSelect = ({value,options,onChange}) => {
  const selectedOption = options.find((o) => o.value===value)
  const customStyles = {
    container: () => ({
      width: 220,
    })
  }
  return <div style={{ position: 'absolute', zIndex: 2, top: '20px', left: '10px' }}>
    <Select
      options={options}
      value={selectedOption}
      styles={customStyles}
      onChange={onChange}
      isSearchable={false}
    />
  </div>
}

const OptionsButton = ({clickHandler}) => {

  const StyledButton = withStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: '4px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'rgb(204, 204, 204)',
      padding: '2px 8px',
      '&:hover': {
        backgroundColor: '#CCC',
      }
    },
    label: {
      fontSize: '14px',
      textTransform: 'capitalize',
      fontWeight: 'normal',
      fontFamily: styledTheme.fonts.button,
    },
  })(Button)

  const buttonCmp = <div style={{ position: 'absolute', zIndex: 1, top: '80px', left: '10px' }}>
    <StyledButton onClick={clickHandler.bind(this)}>
      Options...
    </StyledButton>
  </div>

  return buttonCmp
}

const UserLocationSettingsButton  = ({clickHandler, children, auth}) => {

  const ret = <UserLocationSettingsButtonContainer>
    <UserLocationSettingsButtonInner onClick={clickHandler.bind(this)}>
      {children}
    </UserLocationSettingsButtonInner>
  </UserLocationSettingsButtonContainer>

  return ret

}

const UserLocationSettingsMenuConfirmationBoxContainer  = ({visible, content, cancelBtnClickHandler, saveButtonClickHandler}) => {

  if (!visible) return null

  const styles = {
    outer: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#666',
      borderRadius: 3,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      border: '2px solid black'
    },
    content: {
      display: 'inline-flex',
    },
    buttonsContainer: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      paddingTop: '10px',
    },
    button: {
      border: '2px solid #2F6BE1',
      borderRadius: '3px',
      padding: '4px 8px',
      fontSize: '12px',
      color: '#2F6BE1',
      marginLeft: '10px',
      cursor: 'pointer',
    },
  }

  const ret = <div style={{ position: 'fixed', width: 320, zIndex: 200, bottom: '10px', left: '10px' }}>
    <div style={styles.outer}>
      {content.map( (item,index) => (<div style={styles.content} key={index}>
          {item}
        </div>)
      )}
      <div style={styles.buttonsContainer}>
        <div style={styles.button} onClick={cancelBtnClickHandler} key={1}>
          Cancel
        </div>
        <div style={styles.button} onClick={saveButtonClickHandler} key={2}>
          Save
        </div>
      </div>
    </div>
  </div>

  return ret
}

const UserLocationSettingsMenuContainer  = ({visible, overlayClickHandler, children}) => {

  if (!visible) return null

  const style = {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#666',
    borderRadius: 10,
    padding: 4,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 22,
  }

  const ret = <ModalAndMenuScreenOverlay onClick={e => overlayClickHandler(e)}>
    <div style={{ position: 'fixed', zIndex: 1, bottom: '70px', left: '10px' }}>
      <div style={style}>
        {children}
      </div>
    </div>
  </ModalAndMenuScreenOverlay>

  return ret

}

const UserLocationSettingsMenuItem = ({text, clickHandler, enabled, last}) => {

  const style = {
    padding: 12,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    backgroundColor: 'white',
    cursor: 'pointer',
  }

  if (!enabled) {
    style['cursor'] = 'default'
    style['color'] = '#AAA'
  }

  if (last) {
    style['borderBottomWidth'] = 0
  }

  const ret = <div style={style} onClick={enabled ? clickHandler : null}>
    {text}
  </div>

  return ret

}

const Disclaimer = ({textObject,disclaimerHidden,closeIconHandler}) => !disclaimerHidden && <DisclaimerContainer>
  <DisclaimerCloseIcon onClick={closeIconHandler}/>
  {textObject && textObject.value}
</DisclaimerContainer>

class Map extends React.Component {

  constructor(props) {
    super(props)
    const defaultMetricToDisplay = 'temperature'
    const savedMetricToDisplay = localStorage.getItem('metricToDisplay')
    localStorage.setItem('metricToDisplay',savedMetricToDisplay || defaultMetricToDisplay)
    const defaultMapTypeId = 'roadmap'
    const savedMapTypeId = localStorage.getItem('mapTypeId')
    localStorage.setItem('mapTypeId',savedMapTypeId || defaultMapTypeId)
    const defaultMapShowAllAwsNames = false
    const defaultMapShowLclbStations = false
    const savedMapShowAllAwsNamesString = localStorage.getItem('mapShowAllAwsNames')
    const savedMapShowAllAwsNames = savedMapShowAllAwsNamesString==='true';
    const savedMapShowLclbStationsString = localStorage.getItem('mapShowLclbStations')
    const savedMapShowLclbStations = savedMapShowLclbStationsString==='true';
    localStorage.setItem('mapShowAllAwsNames', savedMapShowAllAwsNames || defaultMapShowAllAwsNames)
    localStorage.setItem('mapShowLclbStations', savedMapShowLclbStations || defaultMapShowLclbStations)

    this.state = {
      center: null,
      zoom: null,
      centerAndZoomInitialized: false,
      disclaimerHidden: (localStorage.getItem('mapViewDisclaimerHidden')==='true') || false,
      userSettingsMenuVisible: false,
      metric_to_display: savedMetricToDisplay || defaultMetricToDisplay,
      showPopupId: null,
      showSiteNameHoverPopupId: null,
      mapVars: null,
      optionsPanelOpen: false,
      mapTypeId: savedMapTypeId || defaultMapTypeId,
      mapShowAllAwsNames: savedMapShowAllAwsNames || defaultMapShowAllAwsNames,
      mapShowLclbStations: savedMapShowLclbStations || defaultMapShowLclbStations,
      draggable: true,
      homeMarkers: [],
      origHomeMarkers: null,
      setMapCenterZoomLevelDialogVisible: false,
      setHomeLocationDialogVisible: false,
      growingSeasonSettingsModalVisible: false,
      activeGrowingSeason:GrowingSeasonDetailModelDefaultData(),
      origActiveGrowingSeason: null,
      showUpgradeRequiredModal: false,
    }

    this.homeMarkers = []
    this.activeMarker = null

  }

  componentDidMount() {
    const { props } = this
    const { dispatch, match, isSignedIn } = props
    dispatch(fetchAppSettings())
    dispatch(fetchMetricDisplayStatuses())
    const siteSlug = match.params.site_slug
    const dataViewSlug = match.params.data_view_slug
    if (dataViewSlug) dispatch(fetchDataViewHash(siteSlug,dataViewSlug))
    const updateHash = {}
    if (isSignedIn) {
      dispatch(fetchMemberSites())
      dispatch(fetchGrowingLocations())
      dispatch(fetchGrowingSeasons())
    } else {
      dispatch(fetchPublicSites())
    }
    if (Object.keys(updateHash).length) {
      this.setState(updateHash)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { props } = this
    const { dispatch, match, isSignedIn, user } = this.props
    const siteSlug = match.params.site_slug
    const dataViewSlug = match.params.data_view_slug
    const prevDataViewSlug = prevProps.match.params.data_view_slug
    const updateHash = {}
    if (dataViewSlug && (dataViewSlug!==prevDataViewSlug)) dispatch(fetchDataViewHash(siteSlug,dataViewSlug))
    if (props.growingLocations.list!==prevProps.growingLocations.list) {
      const homeMarkers = props.growingLocations.list.map((o) => {
        return {
          ...{
            name: o['name'],
            remoteId: o['remote_id'],
            nearestSiteSlug: o['nearest_site_slug'],
            timezoneName: o['timezone_name'],
          },
          ...{ location_coords: o['location_coords'] }
        }
      })
      updateHash['homeMarkers'] = homeMarkers
    }
    if (props.growingSeasons.list!==prevProps.growingSeasons.list) {
      const growingSeasonsCopy = props.growingSeasons.list.slice().sort((a,b) => {
        const dateAInt = DateTime.fromISO(a.sowing_date).toMillis()
        const dateBInt = DateTime.fromISO(b.sowing_date).toMillis()
        return (dateAInt-dateBInt)
      })
      .filter(({harvestDate}) => (typeof harvestDate==='undefined'))
    }

    if (!this.state.centerAndZoomInitialized) {
      initializeMapCenterAndZoomLevel(updateHash, user);
      updateHash['centerAndZoomInitialized'] = true;
    }

    if (Object.keys(updateHash).length) {
      this.setState(updateHash);
    }
  }

  getMapMarkers = (props,state) => {
    const { isSignedIn, company } = props;
    const billingItemAccessPlan = company ? company.billing_item_access_plan : null;
    const showHomeLocation = companyHasHomeLocation(company);

    const nominatedSiteSlugs = []
    if (billingItemAccessPlan && billingItemAccessPlan.site_slug) {
      nominatedSiteSlugs.push(billingItemAccessPlan.site_slug)
      const additionalAwsSlugs = billingItemAccessPlan.additional_aws_slugs || []
      nominatedSiteSlugs.push(...additionalAwsSlugs)
    }
    const ret = props.sites.sites
      .filter(({id, aws_type}) => {
        return aws_type !== "greenbrain" || this.state.mapShowLclbStations;
      })
      .map(({id, name, slug, location, current_data_hash, current_data_last_updated, timezone_name, aws_type },index) => {

      const metricKey = this.state['metric_to_display']
      const metricHash = (current_data_hash['data_array'] && current_data_hash['data_array'].find(({metric_id}) => metric_id===metricKey ))
      const lastUpdatedMs = Date.parse(current_data_last_updated)

      // hide markers if the metric is not part of the current data hash
      // (used by greenbrain sites)
      if (!metricHash) return null;

      return <MapMarker
        key={index}
        lat={location.lat}
        lng={location.lng}
        draggable={true}
        position={'top-left'}
        awsType={aws_type}
        showSiteNameHoverPopup={(this.state.mapShowAllAwsNames) || (id===state.showSiteNameHoverPopupId)}
        clickHandler={this.mapMarkerClickHandler.bind(this,id)}
        mouseOverHandler={this.mapMarkerMouseOverHandler.bind(this,id)}
        mouseOutHandler={this.mapMarkerMouseOutHandler.bind(this,id)}
        name={name}
        slug={slug}
        location={location}
        siteSummaryDataHash={current_data_hash}
        lastUpdated={lastUpdatedMs}
        timezone={timezone_name}
        nominated={nominatedSiteSlugs.includes(slug)}
        closeStatusPopupHandler={this.closeStatusPopupHandler.bind(this)}
        metricHash={metricHash}
        metricKey={metricKey}
        routeToDataViewHandler={this.routeToDataView}
	isSignedIn={isSignedIn}
      />
    })
    const stateHomeMarkersArray = state.homeMarkers || []
    if (stateHomeMarkersArray.length || this.state.setHomeLocationDialogVisible) {
      if ((!stateHomeMarkersArray.length || !Object.keys(this.state.homeMarkers[0]).length) && this.state.setHomeLocationDialogVisible) {
        const newHomeMarker = {}
        newHomeMarker['location_coords'] = { ...this.state.center }
        newHomeMarker['name'] = 'Home'
        newHomeMarker['remoteId'] = uuidv4()
        stateHomeMarkersArray.push(newHomeMarker)
        this.homeMarkers = stateHomeMarkersArray
        this.activeMarker = newHomeMarker
        this.setState({ homeMarkers: stateHomeMarkersArray})
      }

      if (isSignedIn && showHomeLocation) {
        let keyVal = 2000
        stateHomeMarkersArray.forEach((homeMarker) => {
          ret.push(<MapDraggableStar
            name={homeMarker.name}
            draggableHomeMarker={true}
            lat={homeMarker['location_coords'].lat}
            lng={homeMarker['location_coords'].lng}
            remoteId={homeMarker['remoteId']}
            key={`${++keyVal}`}
            ref={React.createRef(`activeMapmarkerStar${keyVal}`)}
          />)
        })
      }

    }
    return ret
  }

  metricSelectChangeHandler = (selectedOption) => {
    this.setState({metric_to_display: selectedOption['value']})
    localStorage.setItem('metricToDisplay',selectedOption['value'])
    ReactGA.event({
      category: 'Site Action',
      action: 'Changed Map View Selected Metric',
      label: selectedOption['label'].replace("@"," at "),
    })
  }

  renderShowDataView(props,state) {
    const site = props.site
    const dataView = props.dataView
    const { dispatch } = props
    if (!site || !site.id) return null
    //console.log("XXX props.billingItemAccessPlan",props.billingItemAccessPlan)
    if (dataView) {
      switch (dataView.data_view_type_category) {
        case 'dashboard':
          if (dataView.data_view_type_key==="dashboard") return <Dashboard
            closeDataViewHandler={this.dataViewCloseDataViewHandler}
            dispatch={dispatch}
            site={site}
            dataView={dataView}
            data={props.dataViewHash}
            awsType={site.aws_type}
            user={props.apiAuth?.user}
            billingItemAccessPlan={props.billingItemAccessPlan}
            routeToSiteDataViewsListHandler={this.routeToSiteDataViewsList}
            routeToDataViewHandler={this.routeToDataView}
            showUpgradeRequiredModalHandler={this.showUpgradeRequiredModal}
            fetching={props.dataViewHashFetching}
            fetched={props.dataViewHashFetched}
          />
          break
        case 'graph':
          const options = props.dataViewHash || {}
          const windDirectionSeries = props.windDirectionSeries || {}
          return <Graph
            closeDataViewHandler={this.dataViewCloseDataViewHandler}
            dispatch={dispatch}
            site={site}
            dataView={dataView}
            options={options}
            windDirectionSeries={windDirectionSeries}
            dataViewSummary={props.dataViews}
            ref={this.graphComponent}
            fetching={props.dataViewHashFetching}
            fetched={props.dataViewHashFetched}
          />
        default:
          return null
      }
    }
  }

  repositionMapCenterForStatusPopup = () => {

    let testCount = 0
    const activeMapMarker = this.refs['active-mapmarker-' + this.state.showPopupId]

    const intervalId = setInterval(() => {
      if (testCount > 10) {
        clearInterval(intervalId)
        return true
      }
      testCount++
      if (!activeMapMarker) {
        return true
      }
      const statusPopupBoundingClientRect = activeMapMarker.getStatusPopupBoundingClientRect()
      if (!statusPopupBoundingClientRect) {
        return true
      }
      testCount = 0

      if (statusPopupBoundingClientRect.y < 50) {

        if (this.state.mapVars) {
          const { bounds, size } = this.state.mapVars
          const { y } = this.getDegreesPerPixel(bounds, size)
          const oldCenter = this.state.center
          const newLat = oldCenter.lat+((Math.abs(statusPopupBoundingClientRect.y)+70)*y)
          this.setState({center: {lat: newLat, lng: oldCenter.lng }})
        }
        clearInterval(intervalId)
        return true

      }
      clearInterval(intervalId)
    },200)

  }

  getDegreesPerPixel = (bounds,size) => {
    const xRight = bounds.ne.lng+180
    const xLeft = bounds.nw.lng+180
    const yTop = bounds.ne.lat+180
    const yBottom = bounds.se.lat+180
    const degreesWidth = xRight-xLeft
    const degreesHeight = yTop-yBottom
    return { x: degreesWidth/size.width, y: degreesHeight/size.height }
  }

  mapMarkerClickHandler = (id) => {
    const site = this.props.sites.sites.find((o) => o.id===id)
    ReactGA.event({
      category: 'Site Action',
      action: 'Map: Open Site Status Popup',
      label: site.name,
    })
    this.setState({
      showPopupId: id,
      showSiteNameHoverPopupId: null,
    })
  }

  mapMarkerMouseOverHandler = (id) => {
    this.setState({showSiteNameHoverPopupId: id})
  }

  mapMarkerMouseOutHandler = (id) => {
    this.setState({showSiteNameHoverPopupId: null})
  }

  closeStatusPopupHandler = () => {
    this.setState({ showPopupId: null })
  }

  mapTypeIdChangHandler = (event) => {
    const value = event.target.value
    ReactGA.event({
      category: 'Site Action',
      action: 'Map Options: Set Map Type',
      label: value.toString(),
    })
    this.setState({ mapTypeId: value })
    localStorage.setItem('mapTypeId',value)
  }

  mapShowAllAwsNamesChangeHandler = (event) => {
    const value = event.target.checked
    ReactGA.event({
      category: 'Site Action',
      action: 'Map Options: Set Show AWS Names',
      label: value.toString(),
    })
    this.setState({ mapShowAllAwsNames: value })
    localStorage.setItem('mapShowAllAwsNames',value)
  }

  mapShowLclbStationsChangeHandler = (event) => {
    const value = event.target.checked
    ReactGA.event({
      category: 'Site Action',
      action: 'Map Options: Set Show LCLB Stations',
      label: value.toString(),
    })
    this.setState({ mapShowLclbStations: value })
    localStorage.setItem('mapShowLclbStations',value)
  }

  closeOptionsPanelHandler = () => {
    this.setState({ optionsPanelOpen: false })
  }

  setDisclaimerHiddenTrue = () => {
    localStorage.setItem('mapViewDisclaimerHidden',true)
    this.setState({ disclaimerHidden: true })
  }

  userSettingsButtonClickHandler = () => {
    this.setState({ userSettingsMenuVisible: !this.state.userSettingsMenuVisible })
  }

  userSettingsOverlayClickHandler = (event) => {
    if (event.target === event.currentTarget) {
      this.setState({ userSettingsMenuVisible: false })
    }
  }

  userSettingsMenuSetCentreZoomLevelClickHandler = () => {
    this.setState({
      setMapCenterZoomLevelDialogVisible: true,
      userSettingsMenuVisible: false,
    })
  }

  userSettingsMenuSetHomeLocationClickHandler = () => {

    const origHomeMarkers = this.state.homeMarkers.map((o) => ({ ...o, ...{ location_coords: { ...o.location_coords }}}))
    const updateHash = {
      origHomeMarkers: origHomeMarkers,
      setHomeLocationDialogVisible: true,
      userSettingsMenuVisible: false,
    }
    if (origHomeMarkers.length) {
      const marker = origHomeMarkers[0]
      updateHash['center'] = marker.location_coords
    }
    this.setState(updateHash)

    const homeMarkers = this.state.homeMarkers.map((o) => { return { ...o, ...o['location_coords'] } })
    this.homeMarkers = homeMarkers
    this.activeMarker = homeMarkers.length ? homeMarkers[0] : null

  }

  setMapCenterZoomLevelDialogSaveButtonClickHandler = () => {
    localStorage.setItem('savedMapZoom',this.state.zoom)
    localStorage.setItem('savedMapCenter',JSON.stringify(this.state.center))
    const { zoom, center } = this.state
    const params = { map_default_zoom_level: zoom, map_default_centre_coords: center }
    const userId = this.props.apiAuth.user.id
    this.props.dispatch(updateUserMapCenterAndZoomLevel(userId,params))
    this.setState({ setMapCenterZoomLevelDialogVisible: false })
  }

  setMapCenterZoomLevelDialogCancelButtonClickHandler = () => {
    this.setState({ setMapCenterZoomLevelDialogVisible: false })
  }

  setHomeLocationDialogSaveButtonClickHandler = () => {
    this.setState({ setHomeLocationDialogVisible: false })
    const homeMarker = this.state.homeMarkers[0]
    const updateParams = {
      name: homeMarker['name'],
      location_coords: homeMarker['location_coords'],
    }
    this.props.dispatch(updateGrowingLocation(homeMarker['remoteId'],updateParams))
  }

  setHomeLocationDialogCancelButtonClickHandler = () => {
    const homeMarkers = this.state.origHomeMarkers.map((o) => ({ ...o, ...{ location_coords: { ...o.location_coords }}}))
    const updateHash = {
      homeMarkers: homeMarkers,
      setHomeLocationDialogVisible: false
    }
    if (homeMarkers.length) {
      const firstHomeMarker = homeMarkers[0]
      updateHash['center'] = firstHomeMarker.location_coords
    }
    this.setState(updateHash)
  }

  userSettingsMenuSetGrowingSeasonDetailsClickHandler = () => {
    if ((this.state.homeMarkers || []).length > 0) {
      this.setState({
        growingSeasonSettingsModalVisible: true,
        userSettingsMenuVisible: false,
        activeGrowingSeason: GrowingSeasonDetailModelDefaultData(),
      });
    }else{
      this.setState({
        userSettingsMenuVisible: false,
      });
      confirmAlert({
        title: 'Please set the Home location first',
        message: 'The Home location can be set/edited via the bottom-right menu.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
            }
          }
        ]
      })
    }
  }

  growingSeasonDetailsSubmitHandler = () => {
    const { activeGrowingSeason } = this.state
    if (activeGrowingSeason.locationOption == "home" || (activeGrowingSeason.locationOption == "custom" && IsGrowingSeasonLocationValid(activeGrowingSeason.location))) {
      const updateParams = ConvertGrowingSeasonUpdateParams(activeGrowingSeason)
      const remoteId = uuidv4()
      this.props.dispatch(updateGrowingSeason(remoteId, updateParams))
      activeGrowingSeason['remoteId'] = remoteId
      this.setState({
        activeGrowingSeason: activeGrowingSeason,
        growingSeasonSettingsModalVisible: false,
      })
    }
  }

  growingSeasonDetailsCancelHandler = () => {
    this.setState({
      activeGrowingSeason: GrowingSeasonDetailModelDefaultData(),
      growingSeasonSettingsModalVisible: false
    })
  }

  growingSeasonNameChangeHandler = event => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason['name'] = event.target.value
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  growingSeasonLocationChangeHandler = event => {
    const {activeGrowingSeason} = this.state
    activeGrowingSeason['location'] = event.target.value
    this.setState({activeGrowingSeason: activeGrowingSeason})
  }

  growingSeasonDateChangeHandler = (fieldName,dateVal) => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason[fieldName] = dateVal.set({hour: 0, minute: 0})
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  growingSeasonRadioChangeHandler = (fieldName,option) => {
    const { activeGrowingSeason } = this.state
    activeGrowingSeason[fieldName] = option
    this.setState({ activeGrowingSeason: activeGrowingSeason })
  }

  getGrowingSeasonSettingsModal = (props,state) => {
    return <GrowingSeasonSettingsPanel
      growingSeasonName={state.activeGrowingSeason['name']}
      sowingDate={state.activeGrowingSeason['sowingDate']}
      emergenceDateOption={state.activeGrowingSeason['emergenceDateOption']}
      emergenceDate={state.activeGrowingSeason['emergenceDate']}
      floralInitiationDateOption={state.activeGrowingSeason['floralInitiationDateOption']}
      floralInitiationDate={state.activeGrowingSeason['floralInitiationDate']}
      harvestDateOption={state.activeGrowingSeason['harvestDateOption']}
      harvestDate={state.activeGrowingSeason['harvestDate']}
      locationOption={state.activeGrowingSeason['locationOption']}
      location={state.activeGrowingSeason['location']}
      nameChangeHandler={this.growingSeasonNameChangeHandler}
      dateChangeHandler={this.growingSeasonDateChangeHandler}
      radioChangeHandler={this.growingSeasonRadioChangeHandler}
      submitHandler={this.growingSeasonDetailsSubmitHandler}
      cancelHandler={this.growingSeasonDetailsCancelHandler}
      locationChangeHandler={this.growingSeasonLocationChangeHandler}
    />
  }

  getUnitsHash = (appSettingsList) => {
    const unitsHash = {}
    appSettingsList.filter(({category}) => category==="unit_display").forEach(({key,value}) => unitsHash[key] = value)
    return unitsHash
  }

  getTextLabels = (appSettingsList) =>{
    const textHash = {}
    appSettingsList.filter(({category}) => category==="text_label").forEach(({key,value}) => textHash[key] = value)
    return textHash
  }

  routeToDataView = (siteSlug,dataViewSlug) => {
    this.props.dispatch(push(`/map/${siteSlug}/${dataViewSlug}`))
  }

  routeToSiteDataViewsList = (siteSlug) => {
    this.props.dispatch(push(`/sites/${siteSlug}`))
  }

  dataViewCloseDataViewHandler = () => {
    if (window.lastLocation && (window.lastLocation.host===window.location.host)) {
      this.props.history.goBack()
    } else {
      this.props.history.push(`/`)
    }
    this.props.dispatch(clearDataViewHash())
  }

  getSiteStatusPopup = (props,state) => {

    if (!state.showPopupId) return null

    const site = props.sites.sites.find(({id}) => id===state.showPopupId)
    const { name, slug, current_data_hash, current_data_last_updated,
      timezone_name, aws_type, mesonet } = site
    const { isSignedIn, company } = props

    const lastUpdatedMs = Date.parse(current_data_last_updated)

    return <SiteStatusPopup
      name={name}
      isSignedIn={isSignedIn}
      company={company}
      awsType={aws_type}
      isMesonet={mesonet}
      slug={slug}
      siteSummaryDataHash={current_data_hash}
      lastUpdated={lastUpdatedMs}
      timezone={timezone_name}
      closeStatusPopupHandler={this.closeStatusPopupHandler.bind(this)}
      routeToDataViewHandler={this.routeToDataView}
      showUpgradeRequiredModalHandler={this.showUpgradeRequiredModal}
    />

  }

  getOptionsPanel = (props,state) => {
    return <OptionsPanel
      mapTypeIdChangHandler={this.mapTypeIdChangHandler}
      mapTypeId={this.state.mapTypeId}
      mapShowAllAwsNamesChangeHandler={this.mapShowAllAwsNamesChangeHandler}
      mapShowAllAwsNames={this.state.mapShowAllAwsNames}
      mapShowLclbStationsChangeHandler={this.mapShowLclbStationsChangeHandler}
      mapShowLclbStations={this.state.mapShowLclbStations}
      closeOptionsPanelHandler={this.closeOptionsPanelHandler}
    />
  }

  optionsButtonClickHandler = () => {
    ReactGA.event({
      category: 'Site Action',
      action: 'Map: Open Options Panel',
    })
    this.setState({optionsPanelOpen: true})
  }

  getMetricDisplayStatuses = (metricToDisplay) => {
    let filterKey
    if (['current_day_rainfall','previous_day_rainfall'].includes(metricToDisplay)) {
      filterKey = 'daily_rainfall'
      return this.props.metricDisplayStatuses.list.filter(({metric_key, threshold_value}) => ((metric_key===filterKey) && (threshold_value > 0))).sort((a,b) => b.threshold_value-a.threshold_value)
    } else if (['wind_speed_2m','wind_speed_10m'].includes(metricToDisplay)) {
      filterKey = 'wind_speed'
      return this.props.metricDisplayStatuses.list.filter(({metric_key, threshold_value}) => (metric_key===filterKey)).sort((a,b) => b.threshold_value-a.threshold_value)
    } else {
      filterKey = metricToDisplay
      return this.props.metricDisplayStatuses.list.filter(({metric_key}) => metric_key===filterKey).sort((a,b) => b.threshold_value-a.threshold_value)
    }
  }

  onHomeLocationMouseDown = (childKey, childProps, mouse) => {

    if (this.state.setHomeLocationDialogVisible!==true) return
    if (childProps['draggableHomeMarker']!==true) return

    const homeMarkers = this.state.homeMarkers.map((o) => { return { ...o, ...o['location_coords'] } })
    const newHomeMarker = homeMarkers.find(({remoteId}) => remoteId===childProps.remoteId)

    this.homeMarkers = homeMarkers
    this.activeMarker = newHomeMarker

  }

  onHomeLocationMouseClickMove = (childKey, childProps, mouse) => {

    if (this.state.setHomeLocationDialogVisible!==true) return
    if (childProps['draggableHomeMarker']!==true) return

    const homeMarkers = this.homeMarkers
    const newHomeMarker = this.activeMarker
    newHomeMarker.location_coords['lat'] = mouse.lat
    newHomeMarker.location_coords['lng'] = mouse.lng

    this.setState({
      draggable: false,
      homeMarkers: homeMarkers,
    })
  }

  onHomeLocationMouseUp = (childKey, childProps, mouse) => {
    if (this.state.setHomeLocationDialogVisible!==true) return
    if (childProps['draggableHomeMarker']!==true) return
    const newHomeMarker = this.activeMarker
    this.setState({
      draggable: true,
      center: { ...newHomeMarker.location_coords },
    })
  }

  showUpgradeRequiredModal = () => {
    this.setState({ showUpgradeRequiredModal: true })
  }

  closeUpgradeRequiredModal = () => {
    this.setState({ showUpgradeRequiredModal: false })
  }

  renderBody = (props,state) => {

    const { apiAuth, user, company } = props
    const showMenu = companyHasMenu(company);
    const showGrowingSeasons =  companyHasGrowingSeasons(company);

    const permissions = user ? (user.permissions || {}) : {}
    const permissionHomeLocationGrowingSeasons = !!(permissions.owner || permissions.home_location_growing_seasons)

    return <SiteLayout noPadding>
    <HelmetTitle title={'Map'} />
    <div style={{ height: 'calc(100vh - 50px)', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, position: 'relative' }}>
        <div style={{flex: 1, position: 'relative'}}>
          <MetricSelect
            options={MetricsValuesLabels}
            value={this.state['metric_to_display']}
            onChange={this.metricSelectChangeHandler}
          />
          <OptionsButton clickHandler={this.optionsButtonClickHandler} />
          {props.apiAuth.isSignedIn && showMenu && <UserLocationSettingsButton clickHandler={this.userSettingsButtonClickHandler}>
            <MapSettings />
          </UserLocationSettingsButton>}
          {props.apiAuth.isSignedIn &&
            <UserLocationSettingsMenuContainer
              visible={this.state.userSettingsMenuVisible}
              overlayClickHandler={this.userSettingsOverlayClickHandler}
            >
              <UserLocationSettingsMenuItem
                enabled
                text={'Save Map Centre & Zoom Level'}
                clickHandler={this.userSettingsMenuSetCentreZoomLevelClickHandler}
                last={!permissionHomeLocationGrowingSeasons}
              />
              {permissionHomeLocationGrowingSeasons && <UserLocationSettingsMenuItem
                enabled
                text={'Set Home Location'}
                clickHandler={this.userSettingsMenuSetHomeLocationClickHandler}
                last={!showGrowingSeasons}
              />}
              {showGrowingSeasons && permissionHomeLocationGrowingSeasons && <UserLocationSettingsMenuItem
                enabled
                text={'Add Growing Season Details'}
                clickHandler={this.userSettingsMenuSetGrowingSeasonDetailsClickHandler}
                last
              />}
            </UserLocationSettingsMenuContainer>
          }
          <UserLocationSettingsMenuConfirmationBoxContainer
            visible={this.state.setMapCenterZoomLevelDialogVisible}
            content={['Move and zoom the map until it is at the position and zoom level you want to save, then click save.']}
            cancelBtnClickHandler={this.setMapCenterZoomLevelDialogCancelButtonClickHandler}
            saveButtonClickHandler={this.setMapCenterZoomLevelDialogSaveButtonClickHandler}
          />
          <UserLocationSettingsMenuConfirmationBoxContainer
            visible={this.state.setHomeLocationDialogVisible}
            content={['Drag the star to your home location, then click save.']}
            cancelBtnClickHandler={this.setHomeLocationDialogCancelButtonClickHandler}
            saveButtonClickHandler={this.setHomeLocationDialogSaveButtonClickHandler}
          />
          { state.optionsPanelOpen && this.getOptionsPanel(props,state) }
          { state.growingSeasonSettingsModalVisible && this.getGrowingSeasonSettingsModal(props,state) }
          <Disclaimer
            disclaimerHidden={this.state.disclaimerHidden}
            closeIconHandler={this.setDisclaimerHiddenTrue.bind(this)}
            textObject={props.appSettings.appSettings.find(({category, key}) => ( category==='text_label' && key==='map_view_disclaimer' ))}
          />
          <Legend
            textLabels= {this.getTextLabels(props.appSettings.appSettings)}
            metricKey={state['metric_to_display']}
            metricDisplayStatuses={this.getMetricDisplayStatuses(state['metric_to_display'])}
            unitsHash={this.getUnitsHash(props.appSettings.appSettings)}
            isIOS={isIOS}
          />
          { state.showPopupId && this.getSiteStatusPopup(props,state) }
          { state.zoom &&
            <GoogleMapReact
              bootstrapURLKeys={{ key: Environment.GOOGLE_MAP_API_KEY }}
              center={state.center}
              zoom={state.zoom}
              draggable={state.draggable}
              options={{
                fullscreenControl: true,
                zoomControl: !isMobile,
                mapTypeId: state.mapTypeId,
                gestureHandling: "greedy",
              }}
              onDrag={(map) => {
                if (this.state.setHomeLocationDialogVisible && this.homeMarkers) {
                  const updateHash = {}
                  const homeMarkers = this.homeMarkers
                  const newHomeMarker = this.activeMarker
                  newHomeMarker.location_coords['lat'] = map.center.lat()
                  newHomeMarker.location_coords['lng'] = map.center.lng()
                  updateHash['homeMarkers'] = homeMarkers
                  this.setState(updateHash)
                }
              }}
              onDragEnd={(map) => {
                if (this.state.setHomeLocationDialogVisible && this.homeMarkers) {
                  const updateHash = {}
                  const homeMarkers = this.homeMarkers
                  const newHomeMarker = this.activeMarker
                  newHomeMarker.location_coords['lat'] = map.center.lat()
                  newHomeMarker.location_coords['lng'] = map.center.lng()
                  updateHash['homeMarkers'] = homeMarkers
                  this.setState(updateHash)
                }
              }}
              onChange={(newVars) => {
                const updateHash = {
                  mapVars: newVars,
                  center: newVars.center,
                  zoom: newVars.zoom,
                }
                this.setState(updateHash)
                localStorage.setItem('mapCenter',JSON.stringify(newVars.center))
                localStorage.setItem('mapZoom',newVars.zoom)
              }}
              onChildMouseDown={this.onHomeLocationMouseClickMove}
              onChildMouseUp={this.onHomeLocationMouseUp}
              onChildMouseMove={this.onHomeLocationMouseClickMove}
            >
              {this.getMapMarkers(props,state)}
            </GoogleMapReact>
          }
        </div>
      </div>
    </div>
    { props.match.params.data_view_slug && props.dataView && this.renderShowDataView(props,state) }
    <UpgradeRequiredModal
      show={state.showUpgradeRequiredModal}
      overlayClickHandler={this.closeUpgradeRequiredModal}
      closeBtnClickHandler={this.closeUpgradeRequiredModal}
    />
  </SiteLayout>}

  render = () => {
    return this.renderBody(this.props,this.state)
  }

}

export default connect(
  (store, props) => {
    const site = store.sites.sites.find(({slug}) => slug===props.match.params.site_slug)
    const dataViews = site && site.data_views && site.data_views.filter(({data_view_type_category}) => data_view_type_category!=="summary")
    const dataView = dataViews && dataViews.find(({slug}) => slug===props.match.params.data_view_slug)
    const dataViewHash = store.dataViews.dataViewHash
    const windDirectionSeries = dataViewHash && dataViewHash.series && dataViewHash.series.slice().filter(({id}) => id.match(/^wind_direction/))
    const sitesFetching = store.sites.fetching
    const dataViewsFetching = store.dataViews.fetching
    const dataViewHashFetching = store.dataViews.dataViewHashFetching
    const dataViewHashFetched = store.dataViews.dataViewHashFetched
    const growingLocations = store.growingLocations
    const growingSeasons = store.growingSeasons
    const apiAuth = store.apiAuth
    const { isSignedIn, user } = store.apiAuth
    const company = user ? user.company : null
    const billingItemAccessPlan = (store.apiAuth.user && store.apiAuth.user.company) ? store.apiAuth.user.company.billing_item_access_plan : null
    return ({
      apiAuth,
      isSignedIn,
      user,
      company,
      billingItemAccessPlan,
      sites: store.sites,
      site: site,
      sitesFetching: sitesFetching,
      dataViews: dataViews,
      dataView: dataView,
      dataViewHash: dataViewHash,
      dataViewsFetching: dataViewsFetching,
      dataViewHashFetching: dataViewHashFetching,
      dataViewHashFetched: dataViewHashFetched,
      windDirectionSeries: windDirectionSeries,
      appSettings: store.appSettings,
      metricDisplayStatuses: store.metricDisplayStatuses,
      growingSeasons: growingSeasons,
      growingLocations: growingLocations,
    })
  },
)(Map)
