import React from 'react'
import GfdiLegend from './legends/GfdiLegend'
import VtdLegend from './legends/VtdLegend'
import TemperatureLegend from './legends/TemperatureLegend'
import RainbowLegend from './legends/RainbowLegend'
import WindSpeedLegend from './legends/WindSpeedLegend'
import WindSpeedLegendTip from './legends/WindSpeedLegendTip'
import SigmaWLegend from "./legends/SigmaWLegend";
import RainfallLegend from './legends/RainfallLegend'
import SimpleGradientLegend from './legends/SimpleGradientLegend'

import { LegendContainer } from './styled-elements'

const colorBarMetrics = [
  'gfdi',
  'ghcop',
  'cci',
  'etiella',
  'rlem',
  'ag360_flystrike',
  'ag360_heat_chill_risk',
  'downy_mildew'
];

export default class Legend extends React.Component {
  generateInnerContent = (textLabels, metricKey, metricDisplayStatuses, unitsHash) => {

    if (colorBarMetrics.includes(metricKey)) return <GfdiLegend
      metricDisplayStatuses={metricDisplayStatuses}
    />
    if (metricKey==='vtd') return <VtdLegend
      metricDisplayStatuses={metricDisplayStatuses}
    />
    if (metricKey==='temperature') return <TemperatureLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c']}
    />
    if (metricKey==='relative_humidity') return <SimpleGradientLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['percent']}
    />
    if (metricKey==='delta_t') return <SimpleGradientLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c']}
    />
    if (['wind_speed_2m','wind_speed_10m'].includes(metricKey)) return <>
      <WindSpeedLegend
        metricDisplayStatuses={metricDisplayStatuses}
        unit={unitsHash['km_h']}
      />
      <WindSpeedLegendTip 
        tip = {textLabels['wind_legend_tip']}
      />
    </>
    if (metricKey==='dew_point') return <TemperatureLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c']}
    />
    if (metricKey==='sigmaw10' || metricKey==='ann_2hr_sigma_w') return <SigmaWLegend
        metricDisplayStatuses={metricDisplayStatuses}
        unit={unitsHash['sigmaw10']}
    />
    if (['current_day_rainfall','previous_day_rainfall','rainfall_month','rainfall_year'].includes(metricKey)) return <RainfallLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['millimetres']}
    />
    if (metricKey==='evapotranspiration') return <SimpleGradientLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['mm_day_neg1']}
    />
    if (metricKey==='thermal_time') return <RainbowLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c_days']}
    />
    if (metricKey==='vernalisation_time') return <RainbowLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c_days']}
    />
    if (metricKey==='adjusted_thermal_time') return <RainbowLegend
      metricDisplayStatuses={metricDisplayStatuses}
      unit={unitsHash['degrees_c_days']}
    />
    return null
  }

  renderBody = ({ textLabels, metricKey, metricDisplayStatuses, unitsHash, isIOS }) => {
    const innerContent = this.generateInnerContent(textLabels, metricKey, metricDisplayStatuses, unitsHash)
    return innerContent && <LegendContainer isIOS={isIOS}>
      {innerContent}
    </LegendContainer>
  }

  render() {
    return this.renderBody(this.props)
  }
}
